<template>
  <div>
    <h5>Próximos eventos</h5>
    <v-carousel
      v-model="index"
      hide-delimiters
      :show-arrows="sortedResults.length > partiesPerSlide"
      :show-arrows-on-hover="true"
      height="auto"
      :interval="3000 + 500 * partiesPerSlide"
      cycle
    >
      <v-carousel-item
        v-for="i in Math.floor(sortedResults.length / partiesPerSlide) || 1"
        :key="i"
        class="rounded-xl"
      >
        <v-row dense class="mx-0">
          <v-col
            v-for="party in sortedResults.slice(
              (i - 1) * partiesPerSlide,
              i * partiesPerSlide
            )"
            :key="party.id"
            :cols="12 / partiesPerSlide"
          >
            <party-item
              
              :timeStatus="false"
              :party="party"
            ></party-item>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import SHOP from "@/services/shop";
import { mapGetters } from "vuex";
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";
import PartyItem from "@/components/shop/PartyItem.vue";
import PartySort from "@/utils/partySortFunction";

export default {
  metaInfo: {
    title: "Eventos",
  },
  data: () => ({
    index: 0,
    parties: [],
    loading: false,
    error: null,
    results: [],
  }),
  methods: {
    async getParty() {
      try {
        this.loading = true;
        const { parties } = await SHOP.highlight();
        this.parties = parties.map((party) => ({
          ...party,
          path: `/shop/${party.Organization.slug}/${party.slug || party.id}`,
        }));
        this.results = this.parties.sort(PartySort);
      } catch (error) {
        this.error = error.message || error;
      } finally {
        this.loading = false;
      }
    },
    login() {
      this.$router.push({ name: "sign-in", query: { redirect: "/shop" } });
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    sortedResults() {
      return this.results.sort(PartySort);
    },
    partiesPerSlide() {
      if (this.$vuetify.breakpoint.xsOnly) return 1;
      if (this.$vuetify.breakpoint.mdAndDown) return 2;
      if (this.$vuetify.breakpoint.lgAndDown) return 3;
      return 4;
    },
  },
  components: { ColsOrganizer, PartyItem },
  mounted() {
    this.getParty();
  },
};
</script>

<style></style>
