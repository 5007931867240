<template>
  <div>
    <template v-if="party.ageGroup == 0">
      Esse evento é <b>livre</b> para todos os públicos.
    </template>
    <template v-else>
      <p class="mb-0">
        Este evento é para maiores de <b>{{ party.ageGroup }} anos</b>.
      </p>

      <template v-if="!!party.ageWithParent">
        Jovens {{ ageDiference > 2 ? "entre" : "de" }}
        <b
          >{{ party.ageWithParent }}
          <template v-if="ageDiference >= 2">
            {{ ageDiference == 2 ? "ou" : "e" }}
            {{ party.ageGroup - 1 }}
          </template>
          anos</b
        >
        podem entrar acompanhados pelos pais ou responsáveis ou com uma carta de
        autorização assinada pelos mesmos.
      </template>
    </template>
    <a
      v-if="party.authorizationTemplate"
      @click="downloadPdf"
      class="primary--text text-12"
    >
      Baixar modelo de autorização para menores
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    ageDiference() {
      const age = this.party.ageGroup;
      const ageWithParent = this.party.ageWithParent;
      return age - ageWithParent;
    },
  },
  methods: {
    downloadPdf() {
      const link = document.createElement("a");
      link.href = this.party.authorizationTemplate;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
