<template>
  <v-card
    class="rounded-xl"
    :elevation="status.isInProgress ? '10' : status.isActive ? '5' : '3'"
  >
    <div>
      <v-img
        :src="party.cover"
        class="rounded-xl rounded-b-0"
        :aspect-ratio="16 / 9"
        :style="{
          filter: !status.isActive ? `grayscale(80%)` : undefined,
          cursor: 'pointer',
        }"
        @click="coverClick"
        v-ripple
      />
      <div
        class="d-flex flex-column align-start"
        style="position: absolute; top: 10px; left: 10px; gap: 0.5rem"
      >
        <v-chip v-if="status.isInProgress" label small color="warning">
          <v-icon x-small left>mdi-clock-outline</v-icon>
          Em andamento
        </v-chip>
        <v-chip v-if="!status.isActive" label x-small color="secondary">
          <v-icon x-small left>mdi-clock-outline</v-icon>
          Encerrado
        </v-chip>
        <v-chip v-if="party.private" color="info" label x-small>
          <v-icon x-small left>mdi-account-lock</v-icon>
          Privado
        </v-chip>
      </div>
      <div
        v-if="platform"
        class="d-flex flex-column align-start"
        style="position: absolute; top: 10px; right: 10px; gap: 0.5rem"
      >
        <v-chip :color="platform.colors.appBar">
          <v-img
            v-if="platform.logos?.light"
            :src="platform.logos.light"
            height="20px"
            max-width="54px"
            contain
          />
        </v-chip>
      </div>
    </div>
    <v-card-title
      class="font-weight-bold d-block mb-1 text-18"
      style="word-break: normal; line-height: 1.25rem"
    >
      <v-chip
        x-small
        label
        :color="ticketStatusColor[ticketStatus.status]"
        class="float-right ml-2"
      >
        <template v-if="ticketStatus.status !== 'invalid'">
          <v-icon x-small left>
            mdi-{{ ticketStatus.status == "valid" ? "check" : "alert" }}
          </v-icon>
          {{ ticketStatus.length }} ingresso{{
            ticketStatus.length != 1 ? "s" : ""
          }}
          <template v-if="ticketStatus.status == 'pending'">
            pendente{{ ticketStatus.length != 1 ? "s" : "" }}
          </template>
        </template>
        <template v-else>
          <v-icon x-small left>mdi-close</v-icon>
          Nenhum ingresso válido
        </template>
      </v-chip>
      {{ party.name }}
    </v-card-title>
    <v-card-subtitle
      class="d-flex justify-space-between align-center w-full pb-2"
    >
      <span>
        {{
          party.date
            | date(
              status.isActive ? "DD [de] MMM HH:mm" : "DD [de] MMM [de] YYYY"
            )
        }}
      </span>
      <span @click="goToOrg" style="cursor: pointer" class="text-end">
        {{ party.Organization.name }}
      </span>
    </v-card-subtitle>

    <v-card-text class="pb-0">
      <v-alert
        v-if="requireFaceCapture"
        color="warning"
        dense
        border="left"
        class="white--text"
      >
        <v-icon
          class="float-right"
          color="white"
          small
          @click="captureBiometry(true)"
        >
          mdi-help-circle-outline
        </v-icon>
        <div class="d-flex align-start gap-1 mb-1">
          <v-icon color="white" left class="mt-1">
            mdi-face-recognition
          </v-icon>
          <small class="">
            Este evento exige o cadastro facial para entrada. Você ainda não
            cadastrou sua biometria.
          </small>
        </div>
        <v-btn small block depressed @click="captureBiometry()">
          Cadastrar biometria
        </v-btn>
      </v-alert>

      <v-card
        outlined
        v-if="pendingPayments.length"
        class="d-flex mb-3 pt-3 flex-column pa-3 gap-1 rounded-lg"
      >
        <b>
          {{
            pendingPayments.length != 1
              ? "Pagamentos Pendentes"
              : "Pagamento Pendente"
          }}
        </b>
        <router-link
          v-for="([paymentId, tickets], i) of pendingPayments"
          :key="paymentId"
          :to="{ name: 'app.payment.details', params: { id: paymentId } }"
        >
          <v-alert
            border="left"
            text
            :color="ticketStatusLabel.pending.color"
            style="cursor: pointer"
            v-ripple
            class="mb-0 pr-1"
            dense
            contentClass="pr-0"
          >
            <div class="d-flex align-center gap-1">
              <b v-if="pendingPayments.length > 1">{{ i + 1 }}.</b>
              <b class="mb-0 text-14 font-weight-medium">
                {{ tickets.length }}
                {{
                  tickets.length != 1
                    ? "Ingressos Pendentes"
                    : "Ingresso Pendente"
                }}
              </b>
              <v-spacer />

              <v-chip
                class="flex-shrink-0"
                label
                x-small
                :color="ticketStatusLabel.pending.color"
              >
                <v-icon x-small left>
                  {{ ticketStatusLabel.pending.icon }}
                </v-icon>
                {{ ticketStatusLabel.pending.text }}
              </v-chip>
              <v-icon color="grey">mdi-chevron-right</v-icon>
            </div>
          </v-alert>
        </router-link>
      </v-card>
      <v-card outlined class="d-flex flex-column pa-3 mb-3 gap-1 rounded-lg">
        <b v-if="groupedTickets.valid.length || groupedTickets.invalid.length">
          {{
            ticketStatus.status === "valid"
              ? groupedTickets.valid.length != 1
                ? "Seus Ingressos"
                : "Seu Ingresso"
              : groupedTickets.invalid.length != 1
              ? "Seus Ingressos"
              : "Seu Ingresso"
          }}
        </b>
        <span v-else> Você não possui ingressos para este evento </span>

        <v-data-iterator
          v-if="groupedTickets.valid.length || groupedTickets.invalid.length"
          :items="[...groupedTickets.valid, ...groupedTickets.invalid]"
          item-key="id"
          :page.sync="page"
          :items-per-page="5"
          :hide-default-footer="true"
        >
          <template v-slot:default="{ items }">
            <div class="d-flex flex-column gap-1">
              <component
                :is="platform ? 'a' : 'router-link'"
                v-for="(ticket, i) of items"
                :key="i"
                :href="
                  platform
                    ? `https://${platform.domain}/app/ticket/${ticket.id}`
                    : undefined
                "
                :to="{ name: 'app.ticket.details', params: { id: ticket.id } }"
              >
                <v-alert
                  :border="ticket.TicketEntry ? undefined : 'left'"
                  text
                  :color="ticketStatusLabel[ticket.Payment.status].color"
                  style="cursor: pointer"
                  v-ripple
                  class="mb-0 pr-1"
                  dense
                  contentClass="pr-0"
                >
                  <div class="d-flex align-center gap-1">
                    <b
                      v-if="
                        [...groupedTickets.valid, ...groupedTickets.invalid]
                          .length > 1
                      "
                    >
                      {{ i + 1 + (page - 1) * 5 }}.
                    </b>
                    <b class="mb-0 text-14 font-weight-medium">
                      {{ ticket.TicketBlock.TicketGroup.name }} •
                      {{ ticket.TicketBlock.name }}
                    </b>

                    <v-spacer />
                    <div class="d-flex flex-column align-end gap-1">
                      <v-chip
                        v-if="ticket.TicketEntry"
                        label
                        x-small
                        class="ml-1"
                      >
                        Usado
                      </v-chip>
                      <v-chip
                        v-else
                        class="flex-shrink-0"
                        label
                        x-small
                        :color="ticketStatusLabel[ticket.Payment.status].color"
                      >
                        <v-icon x-small left>
                          {{ ticketStatusLabel[ticket.Payment.status].icon }}
                        </v-icon>
                        {{ ticketStatusLabel[ticket.Payment.status].text }}
                      </v-chip>
                    </div>
                    <v-icon color="grey">mdi-chevron-right</v-icon>
                  </div>
                </v-alert>
              </component>
            </div>
          </template>

          <template
            v-slot:footer
            v-if="
              [...groupedTickets.valid, ...groupedTickets.invalid].length > 5
            "
          >
            <div
              class="d-flex align-end justify-content-center mx-1 mt-2 gap-2"
            >
              <span class="disabled--text lh-1">
                Página {{ page }} de {{ numberOfPages }}
              </span>
              <v-spacer />
              <v-btn
                icon
                small
                depressed
                @click="formerPage"
                :disabled="page == 1"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                depressed
                @click="nextPage"
                :disabled="page == numberOfPages"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-iterator>
      </v-card>
    </v-card-text>
    <div class="d-flex flex-column px-4 pb-4" style="gap: 0.25rem">
      <!-- <v-btn small block text @click="goToParty"> Ver detalhes </v-btn> -->
      <v-btn
        v-if="this.ticketStatus.status === 'pending'"
        block
        :text="buttonText.text"
        color="warning"
        class="mt-2"
        :href="
          platform
            ? `https://${platform.domain}/app/payment/${pendingPayments[0][0]}`
            : undefined
        "
        :to="
          !platform
            ? {
                name: 'app.payment.details',
                params: { id: pendingPayments[0][0] },
              }
            : undefined
        "
      >
        Pagar
      </v-btn>
      <v-btn
        v-else-if="!!buttonText"
        block
        :text="buttonText.text"
        color="primary"
        class="mt-2"
        :href="
          platform
            ? `https://${platform.domain}/shop/${party.Organization.slug}/${party.slug}`
            : undefined
        "
        :to="
          !platform
            ? {
                name: 'shop.party',
                params: {
                  orgSlug: party.Organization.slug,
                  partyId: party.id,
                },
              }
            : undefined
        "
      >
        {{ buttonText.label }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import PartyStatus from "@/utils/partyStatus";
import Payment from "@/utils/payment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      page: 1,
      ticketStatusLabel: Payment.ticketStatus,
      ticketStatusColor: {
        valid: "success",
        invalid: "error",
        pending: "warning",
      },
    };
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },

    coverClick() {
      if (this.ticketStatus.status === "invalid") return this.buyTickets();
      if (this.ticketStatus.status === "valid")
        return this.goToTicket(this.groupedTickets.valid[0]);
      this.buyTickets();
    },
    goToOrg() {
      if (this.platform) {
        const url = `https://${this.platform.domain}/shop/${this.party.Organization.slug}`;
        window.location.replace(url);
        return;
      }

      this.$router.push({
        name: "shop.organization",
        params: {
          orgSlug: this.party.Organization.slug,
        },
      });
    },
    buyTickets() {
      if (this.platform) {
        const url = `https://${this.platform.domain}/shop/${this.party.Organization.slug}/${this.party.slug}`;
        window.location.replace(url);
        return;
      }

      this.$router.push({
        name: "shop.party",
        params: {
          orgSlug: this.party.Organization.slug,
          partyId: this.party.id,
        },
      });
    },
    goToTicket({ id }) {
      if (this.platform) {
        const url = `https://${this.platform.domain}/app/ticket/${id}`;
        window.location.replace(url);
        return;
      }

      this.$router.push({
        name: "app.ticket.details",
        params: { id: id },
      });
    },
    captureBiometry(help = false) {
      this.$router.push({
        name: "face.capture",
        query: { help: help || undefined, redirect: this.$route.fullPath },
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    numberOfPages() {
      return Math.ceil(
        (this.groupedTickets.valid.length +
          this.groupedTickets.invalid.length) /
          5
      );
    },
    platform() {
      return this.party.Organization.Platform;
    },
    pendingPayments() {
      return Object.entries(
        this.groupedTickets.pending.reduce((acc, ticket) => {
          const paymentId = ticket.Payment.id;
          if (!acc[paymentId]) acc[paymentId] = [];
          acc[paymentId].push(ticket);
          return acc;
        }, {})
      );
    },
    buttonText() {
      if (!this.status.isActive)
        return { label: "Ver página do evento", text: true };
      if (this.ticketStatus.status === "invalid")
        return { label: "Comprar ingresso", text: false };
      if (
        !!this.party.maxTickets &&
        this.groupedTickets.valid.length < this.party.maxTickets
      )
        return { label: "Comprar mais ingressos", text: false };
      else return { label: "Ver página do evento", text: true };
    },
    status() {
      return new PartyStatus(this.party);
    },
    groupedTickets() {
      return this.party.tickets.reduce(
        (acc, ticket) => {
          const status = ticket.Payment.status;
          if (status === "pending") acc.pending.push(ticket);
          else if (["refunded", "disputed"].includes(status))
            acc.invalid.push(ticket);
          else if (status !== "canceled") acc.valid.push(ticket);
          return acc;
        },
        {
          valid: [],
          invalid: [],
          pending: [],
        }
      );
    },
    ticketStatus() {
      const hasActive = this.groupedTickets.valid;

      if (hasActive.length) {
        const hasPending = this.groupedTickets.pending;

        if (hasPending.length)
          return { status: "pending", length: hasPending.length };

        return { status: "valid", length: hasActive.length };
      }
      const hasCanceled = this.groupedTickets.invalid;

      return { status: "invalid", length: hasCanceled.length };
    },
    requireFaceCapture() {
      if (!this.user?.Biometrics) return false; // User has no biometrics
      const userFace = this.user.Biometrics.find(
        (biometric) => biometric.type === "FACE"
      );
      if (userFace) return false;
      if (
        this.groupedTickets.valid.some(
          ({ TicketBlock }) => TicketBlock.TicketGroup.requireBiometry
        )
      )
        return true;
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
